import * as React from 'react';
import { Formik, Form, Field, ErrorMessage, validateYupSchema } from "formik";
import * as Yup from "yup";
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { AnyObject } from 'yup/lib/object';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
        xs: '90vw',
        md: '90vw'
    },
    maxWidth: 400,
    bgcolor: 'background.main',
    borderRadius: 4,
    p: 4,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center'
};

const initialValues = {
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    form: "",
};

const required = "required"

const validationSchema = Yup.object({
    //firstName: Yup.string(),
    //firstNameRequired: Yup.string().required("Required"),
    //lastName: Yup.string(),
    //lastNameRequired: Yup.string().required("Required"),
    //phone: Yup.string(),
    //phoneRequired: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email format").required("Required"),
    form: Yup.string(),
    //messageRequired: Yup.string().required("Required")
});

const FormModal = (props: {
    open: boolean,
    siteId: string,
    formDefinition,
    closeModal
}) => {
    return <Modal
        open={props.open}
        onClose={props.closeModal}
        aria-describedby="modal-modal-description"
    >
        <Stack sx={style} id="modal-modal-description" spacing={2}>
            <Typography variant="h5" color="primary">
                {props.formDefinition.title}
            </Typography>
            <LeadForm data={props} closeModal={props.closeModal} />
        </Stack>
    </Modal>
}
declare global {
    interface Window {
        gtag_report_conversion: Function | undefined;
    }
}

function LeadForm({ data, closeModal }) {

    const onSubmit = (values, onSubmitProps) => {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
            event: "gtm.formSubmit",
            formId: "evergrowForm",
        })
        //window.fbq = window.fbq || []
        //window.fbq("track", "Lead")
        onSubmitProps.setSubmitting(false);
        (async () => {
            try {
                const response = await fetch(process.env.GATSBY_LEAD_URL as string,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'x-api-key': process.env.GATSBY_LEAD_API_KEY!,
                        },
                        redirect: 'follow',
                        referrerPolicy: 'no-referrer',
                        body: JSON.stringify({
                            "siteId": data.siteId,
                            "fromEmail": values.email,
                            "form": values.form
                        })
                    });

                if (window.gtag_report_conversion) {
                    window.gtag_report_conversion()
                    console.log("submitted gtag_report_conversion")
                }

                else {
                    console.log("No gtag_report_conversion")
                }
            } finally {
                closeModal();
            }
        }
        )()
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {(formik) => {
                return (
                    <Form>
                        <Stack spacing={2}>
                            {!data.formDefinition.emailHeader || !data.formDefinition.emailDisabled &&
                                <Field name={"email"}>
                                    {(props) => {
                                        const { field, form, meta } = props;
                                        return (

                                            <TextField
                                                id={"email"}
                                                label={data.formDefinition.emailHeader}
                                                {...field}
                                                helperText={
                                                    meta.touched && meta.error ? meta.error : null
                                                }
                                                error={meta.touched && meta.error ? true : false}
                                                sx={{ flexGrow: 1 }}
                                            />
                                        );
                                    }}
                                </Field>
                            }
                            {!data.formDefinition.formHeader || !data.formDefinition.messageDisabled &&
                                <Field name={"form"}>
                                    {(props) => {
                                        const { field, form, meta } = props;
                                        return (
                                            <TextField
                                                id={"form"}
                                                label={data.formDefinition.formHeader}
                                                {...field}
                                                helperText={
                                                    meta.touched && meta.error ? meta.error : null
                                                }
                                                error={meta.touched && meta.error ? true : false}
                                                rows={2}
                                                multiline
                                                sx={{ flexGrow: 1 }}
                                            />
                                        );
                                    }}
                                </Field>
                            }
                            <Button
                                type="submit"
                                variant={"contained"}
                                color="secondary"
                                sx={{
                                    borderRadius: 2,
                                    marginTop: "0",
                                    padding: "12px 12px 12px 12px",
                                    fontWeight: "bold",
                                }}
                                size="large"
                                disabled={!formik.isValid || formik.isSubmitting}
                            >
                                Send
                            </Button>
                        </Stack>
                    </Form>
                );
            }}
        </Formik>
    );
}

export default FormModal

